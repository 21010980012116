import type { AxiosResponse } from "axios"
import Pager from "./Pager"

export default class Pagination<T> {
  items: T[] = []

  offset: number = 0
  totalCount: number = 0
  limit: number = 10

  get pager() {
    return new Pager(this.limit).withPage(this.page)
  }

  get page() {
    return Math.floor(this.offset / this.limit) + 1
  }

  set page(page: number) {
    // ignore
  }

  get totalPages() {
    return Math.ceil(this.totalCount / this.limit)
  }

  static fromResponse<T>(resp: AxiosResponse, items: T[]) {
    const result = new this<T>()

    result.items = items
    result.offset = parseInt(resp.headers['x-pager-offset'])
    result.totalCount = parseInt(resp.headers['x-pager-total-count'])
    result.limit = parseInt(resp.headers['x-pager-limit'])
    return result
  }
}
