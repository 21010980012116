import * as requests from "@/frontend/requests"
import { defineStore, getActivePinia } from 'pinia'
import { ref, type App } from 'vue'
import { useFrontline } from '../lib/frontline'
import type { Account } from '../models'
import _ from 'lodash'
import { useRoute, useRouter } from "vue-router"

export const usePageStore = defineStore('page', () => {
  const router = useRouter()
  const key = ref(_.uniqueId('page-key-'))

  function reinit() {
    key.value = _.uniqueId('page-key-')
  }

  function reload() {
    reinit()
    router.push(router.currentRoute.value.fullPath)
  }

  return { key, reinit, reload }
})
