import * as t from '@/frontend/lib/transforms'
import { Property } from "./Property"
import Pagination from '../lib/Pagination'

export class Link {
  id!: number
  url!: string
  title!: string
  description!: string
  tags!: string[]
  state!: string

  @t.Date sourceCreatedAt!: Date
  @t.Klass(Property) properties!: Property[]
}

export class LinkPage extends Pagination<Link> {
  @t.Klass(Link) items: Link[] = []
  stateCounts!: Record<string, number>
}

export class LinkSummary {
  propertySources: string[] = []
  propertyCodes: string[] = []
  tagNames: string[] = []
}
