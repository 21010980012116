import { useSessionStore } from "@/frontend/stores/session"
import type { App } from "vue"
import { usePageStore } from "../stores/page"

export default function(app: App) {
  const router = app.config.globalProperties.$router
  const pinia = app.config.globalProperties.$pinia

  // router.beforeEach(async (to, from) => {
  //   const page = usePageStore(pinia)
  //   // page.reinit()
  // })
}
