import * as t from '@/frontend/lib/transforms'
import { Link } from '../Link'
import Pagination from '@/frontend/lib/Pagination'

export class FavResource {
  id!: number
  url!: string
  favFolderId!: number
  @t.Raw rawData!: Record<string, any>
  @t.Klass(Link) link?: Link

  get title() { return this.rawData.title as string }
}

export class FavResourcePage extends Pagination<FavResource> {
  @t.Klass(FavResource) items: FavResource[] = []
  countsByFavFolder!: Record<number, number>
}
