


export const layouts = {
'frontend/components/AppNavbar': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/frontend/components/AppNavbar.vue'),
'frontend/components/BilibiliSyncCard': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/frontend/components/BilibiliSyncCard.vue'),
'frontend/components/BilibiliSyncStages': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/frontend/components/BilibiliSyncStages.vue'),
'frontend/components/BlankModal': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/frontend/components/BlankModal.vue'),
'frontend/components/CheckboxToggle': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/frontend/components/CheckboxToggle.vue'),
'frontend/components/ConnectionBilibiliCard': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/frontend/components/ConnectionBilibiliCard.vue'),
'frontend/components/ConnectionCard': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/frontend/components/ConnectionCard.vue'),
'frontend/components/ConnectionGithubCard': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/frontend/components/ConnectionGithubCard.vue'),
'frontend/components/ConnectionPocketCard': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/frontend/components/ConnectionPocketCard.vue'),
'frontend/components/DoubanConnectionCard': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/frontend/components/DoubanConnectionCard.vue'),
'frontend/components/DoubanSyncStages': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/frontend/components/DoubanSyncStages.vue'),
'frontend/components/ErrorAlert': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/frontend/components/ErrorAlert.vue'),
'frontend/components/Link': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/frontend/components/Link.vue'),
'frontend/components/PageHeader': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/frontend/components/PageHeader.vue'),
'frontend/components/PageTitle': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/frontend/components/PageTitle.vue'),
'frontend/components/PaginationBar': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/frontend/components/PaginationBar.vue'),
'frontend/components/RLink': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/frontend/components/RLink.vue'),
'frontend/components/RouterSortLink': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/frontend/components/RouterSortLink.vue'),
'frontend/components/SortLink': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/frontend/components/SortLink.vue'),
'frontend/components/SynchronizationCard': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/frontend/components/SynchronizationCard.vue'),
'frontend/components/SynchronizationStage': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/frontend/components/SynchronizationStage.vue'),
'frontend/layouts/default': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/frontend/layouts/default.vue'),
'frontend/pages/dashboard': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/frontend/pages/dashboard.vue'),
'frontend/pages/index': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/frontend/pages/index.vue'),
'frontend/pages/sign_in': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/frontend/pages/sign_in.vue'),
'frontend/components/controls/InputNumber': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/frontend/components/controls/InputNumber.vue'),
'frontend/components/controls/InputPassword': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/frontend/components/controls/InputPassword.vue'),
'frontend/components/controls/InputText': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/frontend/components/controls/InputText.vue'),
'frontend/components/controls/Select': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/frontend/components/controls/Select.vue'),
'frontend/components/controls/Textarea': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/frontend/components/controls/Textarea.vue'),
'frontend/components/simple_form/Form': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/frontend/components/simple_form/Form.vue'),
'frontend/components/simple_form/FormGroup': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/frontend/components/simple_form/FormGroup.vue'),
'frontend/pages/connections/ConnectionNewFrame': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/frontend/pages/connections/ConnectionNewFrame.vue'),
'frontend/pages/connections/index': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/frontend/pages/connections/index.vue'),
'frontend/pages/links/BatchActionFrame': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/frontend/pages/links/BatchActionFrame.vue'),
'frontend/pages/links/FieldPty': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/frontend/pages/links/FieldPty.vue'),
'frontend/pages/links/FieldTag': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/frontend/pages/links/FieldTag.vue'),
'frontend/pages/links/SearchBar': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/frontend/pages/links/SearchBar.vue'),
'frontend/pages/links/index': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/frontend/pages/links/index.vue'),
'frontend/pages/oauth/authorize': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/frontend/pages/oauth/authorize.vue'),
'vendor/shadcn/ui/alert-dialog/AlertDialog': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/vendor/shadcn/ui/alert-dialog/AlertDialog.vue'),
'vendor/shadcn/ui/alert-dialog/AlertDialogAction': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/vendor/shadcn/ui/alert-dialog/AlertDialogAction.vue'),
'vendor/shadcn/ui/alert-dialog/AlertDialogCancel': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/vendor/shadcn/ui/alert-dialog/AlertDialogCancel.vue'),
'vendor/shadcn/ui/alert-dialog/AlertDialogContent': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/vendor/shadcn/ui/alert-dialog/AlertDialogContent.vue'),
'vendor/shadcn/ui/alert-dialog/AlertDialogDescription': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/vendor/shadcn/ui/alert-dialog/AlertDialogDescription.vue'),
'vendor/shadcn/ui/alert-dialog/AlertDialogFooter': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/vendor/shadcn/ui/alert-dialog/AlertDialogFooter.vue'),
'vendor/shadcn/ui/alert-dialog/AlertDialogHeader': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/vendor/shadcn/ui/alert-dialog/AlertDialogHeader.vue'),
'vendor/shadcn/ui/alert-dialog/AlertDialogTitle': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/vendor/shadcn/ui/alert-dialog/AlertDialogTitle.vue'),
'vendor/shadcn/ui/alert-dialog/AlertDialogTrigger': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/vendor/shadcn/ui/alert-dialog/AlertDialogTrigger.vue'),
'vendor/shadcn/ui/alert/Alert': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/vendor/shadcn/ui/alert/Alert.vue'),
'vendor/shadcn/ui/alert/AlertDescription': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/vendor/shadcn/ui/alert/AlertDescription.vue'),
'vendor/shadcn/ui/alert/AlertTitle': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/vendor/shadcn/ui/alert/AlertTitle.vue'),
'vendor/shadcn/ui/badge/Badge': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/vendor/shadcn/ui/badge/Badge.vue'),
'vendor/shadcn/ui/button/Button': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/vendor/shadcn/ui/button/Button.vue'),
'vendor/shadcn/ui/card/Card': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/vendor/shadcn/ui/card/Card.vue'),
'vendor/shadcn/ui/card/CardContent': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/vendor/shadcn/ui/card/CardContent.vue'),
'vendor/shadcn/ui/card/CardDescription': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/vendor/shadcn/ui/card/CardDescription.vue'),
'vendor/shadcn/ui/card/CardFooter': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/vendor/shadcn/ui/card/CardFooter.vue'),
'vendor/shadcn/ui/card/CardHeader': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/vendor/shadcn/ui/card/CardHeader.vue'),
'vendor/shadcn/ui/card/CardTitle': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/vendor/shadcn/ui/card/CardTitle.vue'),
'vendor/shadcn/ui/card/CardTopState': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/vendor/shadcn/ui/card/CardTopState.vue'),
'vendor/shadcn/ui/container/Container': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/vendor/shadcn/ui/container/Container.vue'),
'vendor/shadcn/ui/dialog/Dialog': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/vendor/shadcn/ui/dialog/Dialog.vue'),
'vendor/shadcn/ui/dialog/DialogClose': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/vendor/shadcn/ui/dialog/DialogClose.vue'),
'vendor/shadcn/ui/dialog/DialogContent': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/vendor/shadcn/ui/dialog/DialogContent.vue'),
'vendor/shadcn/ui/dialog/DialogDescription': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/vendor/shadcn/ui/dialog/DialogDescription.vue'),
'vendor/shadcn/ui/dialog/DialogFooter': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/vendor/shadcn/ui/dialog/DialogFooter.vue'),
'vendor/shadcn/ui/dialog/DialogHeader': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/vendor/shadcn/ui/dialog/DialogHeader.vue'),
'vendor/shadcn/ui/dialog/DialogScrollContent': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/vendor/shadcn/ui/dialog/DialogScrollContent.vue'),
'vendor/shadcn/ui/dialog/DialogTitle': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/vendor/shadcn/ui/dialog/DialogTitle.vue'),
'vendor/shadcn/ui/dialog/DialogTrigger': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/vendor/shadcn/ui/dialog/DialogTrigger.vue'),
'vendor/shadcn/ui/checkbox/Checkbox': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/vendor/shadcn/ui/checkbox/Checkbox.vue'),
'vendor/shadcn/ui/checkbox/InputCheckbox': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/vendor/shadcn/ui/checkbox/InputCheckbox.vue'),
'vendor/shadcn/ui/dropdown-menu/DropdownMenu': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/vendor/shadcn/ui/dropdown-menu/DropdownMenu.vue'),
'vendor/shadcn/ui/dropdown-menu/DropdownMenuCheckboxItem': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/vendor/shadcn/ui/dropdown-menu/DropdownMenuCheckboxItem.vue'),
'vendor/shadcn/ui/dropdown-menu/DropdownMenuContent': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/vendor/shadcn/ui/dropdown-menu/DropdownMenuContent.vue'),
'vendor/shadcn/ui/dropdown-menu/DropdownMenuGroup': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/vendor/shadcn/ui/dropdown-menu/DropdownMenuGroup.vue'),
'vendor/shadcn/ui/dropdown-menu/DropdownMenuItem': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/vendor/shadcn/ui/dropdown-menu/DropdownMenuItem.vue'),
'vendor/shadcn/ui/dropdown-menu/DropdownMenuLabel': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/vendor/shadcn/ui/dropdown-menu/DropdownMenuLabel.vue'),
'vendor/shadcn/ui/dropdown-menu/DropdownMenuRadioGroup': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/vendor/shadcn/ui/dropdown-menu/DropdownMenuRadioGroup.vue'),
'vendor/shadcn/ui/dropdown-menu/DropdownMenuRadioItem': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/vendor/shadcn/ui/dropdown-menu/DropdownMenuRadioItem.vue'),
'vendor/shadcn/ui/dropdown-menu/DropdownMenuSeparator': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/vendor/shadcn/ui/dropdown-menu/DropdownMenuSeparator.vue'),
'vendor/shadcn/ui/dropdown-menu/DropdownMenuShortcut': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/vendor/shadcn/ui/dropdown-menu/DropdownMenuShortcut.vue'),
'vendor/shadcn/ui/dropdown-menu/DropdownMenuSub': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/vendor/shadcn/ui/dropdown-menu/DropdownMenuSub.vue'),
'vendor/shadcn/ui/dropdown-menu/DropdownMenuSubContent': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/vendor/shadcn/ui/dropdown-menu/DropdownMenuSubContent.vue'),
'vendor/shadcn/ui/dropdown-menu/DropdownMenuSubTrigger': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/vendor/shadcn/ui/dropdown-menu/DropdownMenuSubTrigger.vue'),
'vendor/shadcn/ui/dropdown-menu/DropdownMenuTrigger': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/vendor/shadcn/ui/dropdown-menu/DropdownMenuTrigger.vue'),
'vendor/shadcn/ui/nav/Nav': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/vendor/shadcn/ui/nav/Nav.vue'),
'vendor/shadcn/ui/nav/NavItem': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/vendor/shadcn/ui/nav/NavItem.vue'),
'vendor/shadcn/ui/nav/NavList': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/vendor/shadcn/ui/nav/NavList.vue'),
'vendor/shadcn/ui/select/Select': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/vendor/shadcn/ui/select/Select.vue'),
'vendor/shadcn/ui/select/SelectContent': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/vendor/shadcn/ui/select/SelectContent.vue'),
'vendor/shadcn/ui/select/SelectGroup': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/vendor/shadcn/ui/select/SelectGroup.vue'),
'vendor/shadcn/ui/select/SelectItem': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/vendor/shadcn/ui/select/SelectItem.vue'),
'vendor/shadcn/ui/select/SelectItemText': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/vendor/shadcn/ui/select/SelectItemText.vue'),
'vendor/shadcn/ui/select/SelectLabel': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/vendor/shadcn/ui/select/SelectLabel.vue'),
'vendor/shadcn/ui/select/SelectScrollDownButton': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/vendor/shadcn/ui/select/SelectScrollDownButton.vue'),
'vendor/shadcn/ui/select/SelectScrollUpButton': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/vendor/shadcn/ui/select/SelectScrollUpButton.vue'),
'vendor/shadcn/ui/select/SelectSeparator': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/vendor/shadcn/ui/select/SelectSeparator.vue'),
'vendor/shadcn/ui/select/SelectTrigger': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/vendor/shadcn/ui/select/SelectTrigger.vue'),
'vendor/shadcn/ui/select/SelectValue': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/vendor/shadcn/ui/select/SelectValue.vue'),
'vendor/shadcn/ui/pagination/PaginationEllipsis': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/vendor/shadcn/ui/pagination/PaginationEllipsis.vue'),
'vendor/shadcn/ui/pagination/PaginationFirst': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/vendor/shadcn/ui/pagination/PaginationFirst.vue'),
'vendor/shadcn/ui/pagination/PaginationLast': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/vendor/shadcn/ui/pagination/PaginationLast.vue'),
'vendor/shadcn/ui/pagination/PaginationNext': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/vendor/shadcn/ui/pagination/PaginationNext.vue'),
'vendor/shadcn/ui/pagination/PaginationPrev': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/vendor/shadcn/ui/pagination/PaginationPrev.vue'),
'vendor/shadcn/ui/table/Table': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/vendor/shadcn/ui/table/Table.vue'),
'vendor/shadcn/ui/table/TableBody': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/vendor/shadcn/ui/table/TableBody.vue'),
'vendor/shadcn/ui/table/TableCaption': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/vendor/shadcn/ui/table/TableCaption.vue'),
'vendor/shadcn/ui/table/TableCell': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/vendor/shadcn/ui/table/TableCell.vue'),
'vendor/shadcn/ui/table/TableEmpty': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/vendor/shadcn/ui/table/TableEmpty.vue'),
'vendor/shadcn/ui/table/TableFooter': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/vendor/shadcn/ui/table/TableFooter.vue'),
'vendor/shadcn/ui/table/TableHead': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/vendor/shadcn/ui/table/TableHead.vue'),
'vendor/shadcn/ui/table/TableHeader': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/vendor/shadcn/ui/table/TableHeader.vue'),
'vendor/shadcn/ui/table/TableRow': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/vendor/shadcn/ui/table/TableRow.vue'),
'vendor/shadcn/ui/tabs/Tabs': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/vendor/shadcn/ui/tabs/Tabs.vue'),
'vendor/shadcn/ui/tabs/TabsContent': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/vendor/shadcn/ui/tabs/TabsContent.vue'),
'vendor/shadcn/ui/tabs/TabsList': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/vendor/shadcn/ui/tabs/TabsList.vue'),
'vendor/shadcn/ui/tabs/TabsTrigger': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/vendor/shadcn/ui/tabs/TabsTrigger.vue'),
'frontend/pages/connections/[connection_id]/bilibili/FavResourceMoveDialog': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/frontend/pages/connections/[connection_id]/bilibili/FavResourceMoveDialog.vue'),
'frontend/pages/connections/[connection_id]/bilibili/fav_resources': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/frontend/pages/connections/[connection_id]/bilibili/fav_resources.vue'),
'frontend/pages/connections/[connection_id]/bilibili/index': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/frontend/pages/connections/[connection_id]/bilibili/index.vue'),
'vendor/shadcn/ui/well/Well': () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/vendor/shadcn/ui/well/Well.vue'),
}
export const createGetRoutes = (router, withLayout = false) => {
  const routes = router.getRoutes()
  if (withLayout) {
      return routes
  }
  return () => routes.filter(route => !route.meta.isLayout)
}

export function setupLayouts(routes) {
  function deepSetupLayout(routes, top = true) {
    return routes.map(route => {
      if (route.children?.length > 0) {
        route.children = deepSetupLayout(route.children, false)
      }
      
      if (top) {
        // unplugin-vue-router adds a top-level route to the routing group, which we should skip.
        const skipLayout = !route.component && route.children?.find(r => (r.path === '' || r.path === '/') && r.meta?.isLayout)  

        if (skipLayout) {
          return route
        }

        if (route.meta?.layout !== false) {
          return { 
            path: route.path,
            component: layouts[route.meta?.layout || 'default'],
            children: route.path === '/' ? [route] : [{...route, path: ''}],
            meta: {
              isLayout: true
            }
          }
        }
      }

      if (route.meta?.layout) {
        return { 
          path: route.path,
          component: layouts[route.meta?.layout],
          children: [ {...route, path: ''} ],
          meta: {
            isLayout: true
          }
        }
      }

      return route
    })
  }

    return deepSetupLayout(routes)

}
