import { Account } from "@/frontend/models"
import type { AxiosResponse } from "axios"
import BaseRequest from "./BaseRequest"

export default {
  Get: class extends BaseRequest<Account | null> {
    endpoint = "/account"
    method = "GET"

    validateStatus(status: number): boolean {
      return super.validateStatus(status) || status === 401
    }

    processResponse(response: AxiosResponse) {
      if (response.status === 401) {
        return null
      }

      return this.responseToObject(Account, response)
    }
  },
}
