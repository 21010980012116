import * as t from '@/frontend/lib/transforms'
import { Synchronization } from '../Synchronization'

export class Profile {
  id!: number
  state!: 'pending' | 'syncing' | 'idle' | 'error' | null
  @t.Date lastSyncAt: Date | null = null

  itemsCount!: number

  @t.Klass(Synchronization) synchronization?: Synchronization
}
