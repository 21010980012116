import * as t from '@/frontend/lib/transforms'
import { Synchronization } from '../Synchronization'

export class User {
  id!: number
  state!: 'pending' | 'syncing' | 'idle' | 'error'
  @t.Date lastSyncAt: Date | null = null
  focancesCounts!: Record<string, number>

  @t.Klass(Synchronization) synchronization?: Synchronization
}
