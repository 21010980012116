import * as requests from "@/frontend/requests"
import { defineStore, getActivePinia } from 'pinia'
import { ref, type App } from 'vue'
import { useFrontline } from '../lib/frontline'
import type { Account } from '../models'

export const useSessionStore = defineStore('session', () => {
  const app = (getActivePinia() as any)._a as App
  const battlefield = app.config.globalProperties.$battlefield
  const frontline = useFrontline(battlefield)

  const account = ref(undefined as Account | null | undefined)

  async function prepare() {
    if (account.value !== undefined) {
      return account
    }

    account.value = await frontline.target(requests.account.Get).fire()
    return account.value
  }

  return { account, prepare }
})
