import * as t from '@/frontend/lib/transforms'

export type SynchronizationStep = {
  code: string
  params: Record<string, any>
  progress: number | null
  total: number | null
}

export class Synchronization {
  token!: string
  state!: 'running' | 'success' | 'failure'
  reason: string | null = null

  stage!: string

  stepCode: string | null = null
  stepProgress: number | null = null
  stepTotal: number | null = null
  @t.Raw stepParams!: Record<string, any>

  ts!: number

  @t.Klass(Date) createdAt!: Date
  @t.Klass(Date) updatedAt!: Date

  get step(): SynchronizationStep | null {
    if (this.stepCode === null) {
      return null
    }

    return { code: this.stepCode, params: this.stepParams, progress: this.stepProgress, total: this.stepTotal }
  }
}
