export const routes = [
  {
    path: '/',
    name: 'root',
    component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/frontend/pages/index.vue'),
    /* no children */
    meta: {
      "auth": false,
      "namespace": "frontend"
    }
  },
  {
    path: '/connections',
    /* internal name: '/connections' */
    /* no component */
    children: [
      {
        path: '',
        name: '/connections/',
        component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/frontend/pages/connections/index.vue'),
        /* no children */
        meta: {
          "namespace": "frontend"
        }
      },
      {
        path: ':connection_id',
        /* internal name: '/connections/[connection_id]' */
        /* no component */
        children: [
          {
            path: 'bilibili',
            /* internal name: '/connections/[connection_id]/bilibili' */
            /* no component */
            children: [
              {
                path: '',
                name: '/connections/[connection_id]/bilibili/',
                component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/frontend/pages/connections/[connection_id]/bilibili/index.vue'),
                /* no children */
                meta: {
                  "namespace": "frontend"
                }
              },
              {
                path: 'fav_resources',
                name: '/connections/[connection_id]/bilibili/fav_resources',
                component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/frontend/pages/connections/[connection_id]/bilibili/fav_resources.vue'),
                /* no children */
                meta: {
                  "namespace": "frontend"
                }
              }
            ],
            meta: {
              "namespace": "frontend"
            }
          }
        ],
        meta: {
          "namespace": "frontend"
        }
      }
    ],
    meta: {
      "namespace": "frontend"
    }
  },
  {
    path: '/dashboard',
    name: '/dashboard',
    component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/frontend/pages/dashboard.vue'),
    /* no children */
    meta: {
      "namespace": "frontend"
    }
  },
  {
    path: '/links',
    /* internal name: '/links' */
    /* no component */
    children: [
      {
        path: '',
        name: '/links/',
        component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/frontend/pages/links/index.vue'),
        /* no children */
        meta: {
          "namespace": "frontend"
        }
      }
    ],
    meta: {
      "namespace": "frontend"
    }
  },
  {
    path: '/oauth',
    /* internal name: '/oauth' */
    /* no component */
    children: [
      {
        path: 'authorize',
        name: '/oauth/authorize',
        component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/frontend/pages/oauth/authorize.vue'),
        /* no children */
        meta: {
          "namespace": "frontend"
        }
      }
    ],
    meta: {
      "namespace": "frontend"
    }
  },
  {
    path: '/sign_in',
    name: '/sign_in',
    component: () => import('/private/var/folders/nw/dmzdfwcj36z2df2_jwj19c_40000gn/T/capsum/Users/sunteya/Workspaces/sun/any2link/app/javascript/frontend/pages/sign_in.vue'),
    /* no children */
    meta: {
      "auth": false,
      "namespace": "frontend"
    }
  }
]
