import { Type } from 'class-transformer'
import * as github from './github'
import * as pocket from './pocket'
import * as bilibili from './bilibili'
import * as douban from './douban'


export const ConnectionSourceTypes = ['Github::User', 'Pocket::Profile', 'Bilibili::User', 'Douban::User'] as const
export type ConnectionSourceType = typeof ConnectionSourceTypes[number]

export class Connection {
  id!: number
  sourceType!: ConnectionSourceType
  sourceId!: number

  @Type((args) => {
    const object = args!.object
    const sourceType = object.source_type as ConnectionSourceType

    if (sourceType === 'Github::User') {
      return github.User
    } else if (sourceType === 'Pocket::Profile') {
      return pocket.Profile
    } else if (sourceType === 'Bilibili::User') {
      return bilibili.User
    } else if (sourceType === 'Douban::User') {
      return douban.User
    } else {
      throw new Error(`Unknown source type: ${sourceType}`)
    }
  })
  source!: github.User | pocket.Profile | bilibili.User | douban.User
}
