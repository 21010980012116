export default class Pager {
  limit: number
  offset: number = 0

  constructor(limit: number) {
    this.limit = limit
  }

  get page(): number {
    return Math.floor(this.offset / this.limit) + 1
  }

  set page(page: number) {
    this.offset = (page - 1) * this.limit
  }

  parsePage(params: Record<string, any>) {
    this.page = parseInt(params['page']) || 1
    return this
  }

  withPage(page: number) {
    this.page = page
    return this
  }
}
