// import "../vendor/bootstrap-tabler-ext"
import "@/vendor/fontawesome"
import "@/vendor/shadcn/main.css"

import "./assets/styles"

import { createApp } from 'vue'

const app = createApp({
  template: '<RouterView />',
})

import * as initializers from "./initializers"
for (const key in initializers) {
  const initializer = initializers[key as keyof typeof initializers]
  initializer(app)
}

app.mount('#app')
