import type { BaseRequest } from "@/frontend/requests"
import type Keyv from "@keyvhq/core"
import type { Axios } from "axios"
import { getCurrentInstance, ref, type Ref, type UnwrapRef } from "vue"

export function useFrontline(battlefield?: Battlefield) {
  if (battlefield == null)  {
    const proxy = getCurrentInstance()!.proxy!
    battlefield = proxy.$battlefield
  }

  return battlefield.frontline
}

export class Frontline {
  troops: BaseRequest<any>[] = []

  weapons!: Axios
  supplies!: Keyv

  retreat() {
    this.troops.forEach(it => it.abort())
    this.troops = []
  }

  target<T extends BaseRequest<any>>(klass: new (...args: any[]) => T): T {
    const troop = new klass()
    this.troops.push(troop)
    troop.frontline = this
    return troop
  }
}

export class Battlefield {
  frontline!: Frontline
  weapons!: Axios
  supplies!: Keyv

  constructor(override?: Partial<Battlefield>) {
    Object.assign(this, override)
  }

  rotation() {
    this.frontline?.retreat()

    this.frontline = new Frontline()
    this.frontline.weapons = this.weapons
    this.frontline.supplies = this.supplies
  }
}

export class Archers {
  list = [] as Promise<any>[]

  wait<T>(target: Promise<T>): Ref<UnwrapRef<T>> {
    const result = ref<T>(null! as any)

    target.then((it) => {
      result.value = ref(it).value
    })

    this.list.push(target)
    return result
  }

  waitFor<T>(result: Ref<T>, target: Promise<T>) {
    target.then((it) => {
      result.value = it
    })

    this.list.push(target)
    return result
  }

  async loose() {
    const arrows = this.list
    this.list = []
    return Promise.all(arrows)
  }

  static ready() {
    const instance = new this()
    return instance
  }
}
