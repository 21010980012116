import { Battlefield } from "@/frontend/lib/frontline"
import { type App } from "vue"

export default function(app: App) {
  const battlefield = new Battlefield({
    weapons: app.config.globalProperties.$axios,
    supplies: app.config.globalProperties.$keyv,
  })
  app.config.globalProperties.$battlefield = battlefield

  battlefield.rotation()

  const router = app.config.globalProperties.$router
  router.beforeResolve((to, from) => {
    const battlefield = app.config.globalProperties.$battlefield
    battlefield.rotation()
  })
}

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $battlefield: Battlefield
  }
}
